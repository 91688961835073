<template>
  <v-main>
    <div class="px-10 pt-10 pb-12 default-layout" >
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </div>
  </v-main>
</template>

<script>
export default {
  name: "DefaultView"
}
</script>

<style lang="scss" scoped>

/*.default-layout {
  height: 100vh;
}*/

</style>
